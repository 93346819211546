<template>
  <div class="bg-white" style="height: 100vh;overflow-y: auto;">
    <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
      <b-row>
        <b-col cols="3" class="d-flex align-items-center">
          <div id="button--prev" style="cursor: pointer;" @click="prevPage">
            <feather-icon size="24" class="text-dark font-weight-bolder" icon="ArrowLeftIcon" />
            <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
          </div>
        </b-col>
        <b-col cols="6" class="d-flex justify-content-center align-items-center">
          <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">Transfer Stok</h1>
        </b-col>
        <b-col cols="3" class="text-right">
          <b-button id="button--simpan" class="px-3" :disabled="isDisabled" @click="nextSubmit">
            Simpan
          </b-button>
        </b-col>
      </b-row>
    </header>
    <b-container class="mt-5 pt-5">
      <b-row class="mx-0 mt-5 justify-content-center">
        <b-col cols="12" md="8" lg="8" class="mb-1">
          <div class="section">
            <div class="transaction__filter--container py-2 pl-0 pr-2 justify-content-between" style="height:82px;">
              <b-button-group>
                <b-button v-for="(item, index) in listFilter" :key="`${item.name}-${index}`"
                  class="filter--button size18 fw-bold-700" :class="{ 'active': item.name == filter }"
                  @click="navigation(item)">
                  {{ item.name }}
                </b-button>
              </b-button-group>
            </div>
          </div>
          <hr />
          <!-- <b-row class="justify-content-center mb-1 mb-lg-5">
            <b-col cols="12" md="8" lg="8">
              <div class="mb-2">
                <span class="text-dark text-darken-4 fw-bold-600 size14">Langkah {{ step }} dari 2 - {{ step == 1 ? 'Lengkapi Info' : 'Tambahkan Produk' }}</span>
              </div>
              <b-progress :value="progressValue" max="100" class="progress-bar-success" variant="success" />
            </b-col>
          </b-row> -->
        </b-col>
        <b-col cols="12" md="8" lg="8">
          <b-form-group class="custom__form--input">
            <label for="v-date">Tanggal Transfer <span class="text-danger">*</span></label>
            <b-form-input id="v-date" v-model="formPayload.date" type="date" class="custom__input"
              style="padding-top: 1rem;" placeholder="dd/mm/yyy" />
          </b-form-group>

          <b-form-group class="custom__form--input" v-if="isLocation || isBranch">
            <label for="v-branch">{{ isLocation ? 'Cabang' : 'Cabang Asal' }}</label>
            <b-form-input id="v-branch" v-model="userData.branch.name" type="text" class="custom__input" disabled />
          </b-form-group>

          <b-form-group class="custom__form--input">
            <label for="">{{ isSupplier ? 'Pemasok' : 'Lokasi SKU Asal' }} <span class="text-danger">*</span></label>
            <v-select v-model="isOrigin" label="name" v-if="isLocation" :options="optionsOrigin"
              placeholder="Pilih Lokasi SKU Asal" @input="setWarehouse" />
            <v-select v-model="isOrigin" label="name" v-else-if="isBranch" :options="optionsOrigin"
              placeholder="Pilih Lokasi SKU Asal" @input="setWarehouse" />
            <v-select v-model="isOrigin" label="name" v-else-if="isSupplier" :options="optionsSupplier"
              placeholder="Pilih Pemasok" @input="setWarehouse" />
          </b-form-group>

          <b-form-group class="custom__form--input" v-if="isBranch || isSupplier">
            <label for="v-branch-destination">Cabang Tujuan <span class="text-danger">*</span></label>
            <v-select v-if="isBranch" label="name" :reduce="option => option.uuid" :options="filteredOptionsBranch"
              placeholder="Pilih Cabang Tujuan" @input="setDestination" />
            <b-form-input v-if="isSupplier" id="v-branch-destination" v-model="userData.branch.name" type="text"
              class="custom__input" disabled />
          </b-form-group>

          <b-form-group class="custom__form--input">
            <label for="">Lokasi SKU Tujuan <span class="text-danger">*</span></label>
            <v-select v-if="isLocation" v-model="formPayload.warehouse_destination_uuid" label="name"
              :reduce="option => option.uuid" :options="filteredOptionsDestination"
              placeholder="Pilih Lokasi SKU Tujuan" />
            <v-select v-else-if="isSupplier" v-model="formPayload.warehouse_destination_uuid" label="name"
              :reduce="option => option.uuid" :options="optionsDestination" placeholder="Pilih Lokasi SKU Tujuan" />
            <v-select v-else v-model="formPayload.warehouse_destination_uuid" label="name"
              :reduce="option => option.uuid" :options="filteredOptionsDestinationDynamic"
              placeholder="Pilih Lokasi SKU Tujuan" />
          </b-form-group>

          <b-form-group class="custom__form--input">
            <label for="v-delivery">No. Surat Jalan</label>
            <v-select v-if="delivery_numbers.length && isSupplier" v-model="formPayload.delivery_number"
              label="delivery_number_note" :options="delivery_numbers" :reduce="option => option.uuid"
              placeholder="No. Surat Jalan" @input="deliveryNumberOnchange" />
            <b-form-input v-else id="v-delivery" v-model="formPayload.delivery_number" type="text" class="custom__input"
              style="padding-top: 1rem;" placeholder="No. Surat Jalan" />
          </b-form-group>

          <b-form-group class="custom__form--input">
            <label for="v-memo">Memo</label>
            <b-form-textarea id="v-memo" v-model="formPayload.memo" rows="5" placeholder="Memo"
              class="custom__textarea" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="8" lg="8" v-if="isSupplier && delivery_numbers.length">
          <div>
            <b-row v-for="(item, index) in optionsProduct" :key=index>
              <b-col md="5">
                <b-form-group class="custom__form--input">
                  <label for="v-produk">Nama Produk</label>
                  <b-form-input id="v-produk" v-model="item.name" type="text" class="custom__input" placeholder="Produk"
                    disabled />
                </b-form-group>
                <!-- <b-form-input v-model="item.uuid" type="text" class="custom__input" style="padding-top: 1rem;" placeholder="No. Surat Jalan" /> -->
                <!-- <div>{{ formPayload.items[index].product_uuid.title }}</div> -->
              </b-col>
              <b-col md="2">
                <b-form-group label="Stock" label-for="stockAvailable">
                  <span class="d-flex align-items-center text-dark fw-bold-600 pl-1" style="background: #EFF1F5;border: 1px solid #E4E5EC;border-radius: 8px;height: 52px;overflow-x: scroll">
                    {{ item.stock }}
                  </span>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group class="custom__form--input">
                  <label for="v-qty">Qty</label>
                  <b-form-input id="v-qty" v-model="formPayload.items[index].qty" type="number" :max="`${item.stock}`" class="custom__input" placeholder="Qty" />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-col>

        <b-col cols="12" md="8" lg="8" v-if="(isSupplier && !delivery_numbers.length && formPayload.supplier_origin_uuid) ||
          (isLocation && formPayload.warehouse_origin_uuid) ||
          (isBranch && formPayload.warehouse_origin_uuid)">
          <div>
            <div>
              <b-form ref="form" class="repeater-form" @submit.prevent="repeateAgain">
                <!-- Row Loop -->
                <b-row v-for="(item, index) in formPayload.items" :id="index" :key="index" ref="row">

                  <!-- Item Name -->
                  <b-col md="5">
                    <b-form-group label="Nama Produk" label-for="item-name">
                      <!-- <v-select v-model="formPayload.items[index].product_uuid" label="title" :options="optionsProduct" placeholder="Pilih Produk" @search="onSearchItem" /> -->
                      <span class="d-flex align-items-center text-dark fw-bold-600 pl-1"
                        style="background: #EFF1F5;border: 1px solid #E4E5EC;border-radius: 8px;height: 52px;overflow-x: scroll"
                        role="button" @click="openProductSelectionModal(index)">
                        {{ formPayload.items[index].product_uuid.title || 'Pilih Produk' }}
                      </span>
                      <input :value="formPayload.items[index].product_uuid" type="text" class="custom__input pl-1"
                        hidden />
                    </b-form-group>
                  </b-col>

                  <!-- Stock -->
                  <b-col md="2">
                    <b-form-group label="Stock" label-for="stockAvailable">
                      <span class="d-flex align-items-center text-dark fw-bold-600 pl-1"
                        style="background: #EFF1F5;border: 1px solid #E4E5EC;border-radius: 8px;height: 52px;overflow-x: scroll">
                        {{ formPayload.items[index].product_uuid.stock || 0 }}
                      </span>
                    </b-form-group>
                  </b-col>

                  <!-- Quantity -->
                  <b-col md="2">
                    <b-form-group label="Qty" label-for="quantity">
                      <input :id="`qty-${index}`" :value="formPayload.items[index].qty" type="text"
                        class="custom__input pl-1" @input="getQty(index)" @change="getQty(index)" />
                    </b-form-group>
                  </b-col>

                  <!-- Remove Button -->
                  <b-col lg="2" md="2" class="text-right align-self-center">
                    <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2"
                      @click="removeItem(index)">
                      <feather-icon icon="XIcon" class="mr-25" />
                      <span>Hapus</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </b-form>
            </div>
            <b-button class="btn-block mb-4" variant="outline-dark border-white btn__add--product"
              @click="repeateAgain">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Tambah Produk</span>
            </b-button>
          </div>
        </b-col>
        <b-col cols="12" md="8" lg="8" v-if="!formPayload.items.length">
          <b-alert
            v-height-fade.appear
            variant="danger"
            :show="!formPayload.items.length"
            class="mb-0"
          >
            <div class="alert-body">
              <feather-icon
                icon="InfoIcon"
                class="mr-50"
              />
              Produk tidak tersedia.
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <AddProductTransferStok @selectedProduct="getSelectedProduct" @search="onSearchItem"
        :load-more-product="loadMoreProduct" :options-product="optionsProduct" :get-items="getItems" :selectedWarehouseName="selectedWarehouseName" :next_page_url="next_page_url"/>
    </b-container>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  BForm, BButton, BButtonGroup, BRow, BCol, BContainer, BProgress, BFormGroup, BFormInput, BFormTextarea, BCard, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  checkPermission, successNotification, errorNotification, formatRupiah, preformatFloat,
} from '@/auth/utils'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import AddProductTransferStok from '@/components/Stock/Modal/AddProductTransferStok.vue'

export default {
  components: {
    BButton,
    BButtonGroup,
    BRow,
    BCol,
    BContainer,
    BFormGroup,
    BFormInput,
    BProgress,
    BForm,
    BFormTextarea,
    vSelect,
    AddProductTransferStok,
    BCard,
    BAlert,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  setup() {
    return {
      checkPermission, successNotification, errorNotification, formatRupiah, preformatFloat,
    }
  },
  computed: {
    isDisabled: function () {
      const hasEmptyProductUuid = this.formPayload.items.some(e => e.product_uuid === null || e.product_uuid === '');
      const hasInvalidQty = !this.formPayload.items.every(e => this.checkIsQtyNumber(e.qty));
      return hasEmptyProductUuid || hasInvalidQty;
    },
    // isDisabled: function() {
    //   if (this.step === 1) {
    //     return this.disabled;
    //   } else {
    //     const hasEmptyProductUuid = this.formPayload.items.some(e => e.product_uuid === null || e.product_uuid === '');
    //     const hasInvalidQty = !this.formPayload.items.every(e => this.checkIsQtyNumber(e.qty));
    //     return hasEmptyProductUuid || hasInvalidQty;
    //   }
    // },
    filteredOptionsBranch() {
      if (this.userData.branch && this.userData.branch.uuid) {
        return this.optionsBranch.filter(option => option.uuid !== this.userData.branch.uuid);
      }
      return this.optionsBranch;
    },
    filteredOptionsDestination() {
      if (this.selectedLocation) {
        if (this.selectedLocation.hasOwnProperty("terms_of_payment_day")) {
          return this.optionsDestination;
        } else {
          return this.optionsDestination.filter(option => option.uuid !== this.selectedLocation.uuid);
        }
      }
      return this.optionsDestination;
    },
    filteredOptionsDestinationDynamic() {
      if (this.selectedLocation) {
        if (this.selectedLocation.hasOwnProperty("terms_of_payment_day")) {
          return this.optionsDestinationDynamic;
        } else {
          return this.optionsDestinationDynamic.filter(option => option.uuid !== this.selectedLocation.uuid);
        }
      }
      return this.optionsDestinationDynamic;
    },
  },
  data() {
    return {
      optionsBranch: [],
      options: [],
      optionsSupplier: [],
      optionsOrigin: [],
      optionsDestination: [],
      optionsOriginForBranch: [],
      optionsDestinationDynamic: [],
      messages: Object,
      isLoading: false,
      isOrigin: '',
      formPayload: {
        date: '',
        warehouse_origin_uuid: '',
        warehouse_destination_uuid: '',
        supplier_origin_uuid: '',
        memo: '',
        delivery_number: '',
        items: [
          {
            product_uuid: '',
            qty: 0,
          },
        ],
      },
      step: this.$store.state.formWizard.step,
      progressValue: 50,
      disabled: false,
      optionsProduct: [],
      delivery_numbers: [],
      page: 1,
      isLoadingLoadMore: false,
      next_page_url: '',
      searchQuery: '',
      selectedLocation: null,
      selectedBranch: '',
      listFilter: [
        {
          name: 'Antar Lokasi',
          permission: '',
        },
        {
          name: 'Antar Cabang',
          permission: '',
        },
        {
          name: 'Pemasok',
          permission: '',
        },
      ],
      filter: 'Antar Lokasi',
      isLocation: true,
      isBranch: false,
      isSupplier: false,
      userData: JSON.parse(localStorage.getItem('userData')),
      selectedWarehouseName: null,
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.formWizard.step': function (value) {
      this.step = value
      this.stepForm()
    },
    formPayload: {
      handler(value) {
        this.disabledStep1(value)
      },
      deep: true,
    },
    step(value) {
      if (value == 2) {
        this.disabled = true
      }
    },

    selectedLocation: "handleSelectedLocationChange",
  },
  mounted() {
    // this.getItems()
    this.disabledStep1(this.formPayload)
  },
  created() {
    this.getSupplier()
    this.getBranches()
    this.getWarehouses()
    // this.getWarehousesDynamic()
  },
  destroyed() {
  },
  methods: {
    validateQty(index) {
      const item = this.optionsProduct[index];
      if (parseFloat(this.formPayload.items[index].qty) > parseFloat(item.stock)) {
        this.formPayload.items[index].qty = parseFloat(item.stock);
      }
    },
    navigation(item) {
      this.filter = item.name
      this.clearItem()
      if (this.filter == 'Antar Lokasi') {
        this.isLocation = true
        this.isBranch = false
        this.isSupplier = false
        this.isOrigin = ''
      } else if (this.filter == 'Antar Cabang') {
        this.isLocation = false
        this.isBranch = true
        this.isSupplier = false
        this.isOrigin = ''
      } else {
        this.isLocation = false
        this.isBranch = false
        this.isSupplier = true
        this.isOrigin = ''
      }
    },
    openProductSelectionModal(index) {
      this.indexProduct = index
      this.$bvModal.show('modal-add-product')
    },
    getSelectedProduct(product) {
      this.formPayload.items[this.indexProduct].product_uuid = {
        label: product.uuid,
        title: product.alias_name,
        stock: product.stock,
      }
    },
    deliveryNumberOnchange() {
      if (this.formPayload.delivery_number) {
        this.getItems()
      }
    },
    handleSelectedLocationChange() {
      this.selectedLocation = this.formPayload.warehouse_origin_uuid
        ? this.optionsOrigin.find(option => option.uuid === this.formPayload.warehouse_origin_uuid)
        : this.formPayload.supplier_origin_uuid
          ? this.optionsOrigin.find(option => option.uuid === this.formPayload.supplier_origin_uuid)
          : null;
    },
    setWarehouse(item) {
      const originalDate = this.formPayload.date;
      this.selectedWarehouseName = item?.name || '-'
      this.clearItem();
      this.formPayload.date = originalDate;

      if (item && item.hasOwnProperty("terms_of_payment_day")) {
        this.formPayload.supplier_origin_uuid = (item && item.uuid) || '';
        this.delivery_numbers = item.delivery_numbers || [];
        delete this.formPayload.warehouse_origin_uuid;
        this.page = 1;
      } else {
        this.formPayload.warehouse_origin_uuid = (item && item.uuid) || '';
        this.delivery_numbers = [];
        delete this.formPayload.supplier_origin_uuid;
        this.page = 1;
      }
      if (item == null) {
        this.delivery_numbers = [];
      }

      this.formPayload.delivery_number = '';

      if (!this.delivery_numbers.length) {
        this.getItems();
      }

      this.optionsProduct = []
      this.handleSelectedLocationChange();
    },
    setDestination(item) {
      this.selectedBranch = item
      this.formPayload.warehouse_destination_uuid = ''
      this.getWarehousesDynamic()
    },
    getQty(index) {
      const qty = document.getElementById(`qty-${index}`)
      qty.value = this.formatRupiah(qty.value, '')
      this.formPayload.items[index].qty = qty.value
    },
    async getItemsDetail(id) {
      await this.$store.dispatch('parentProduct/getData', {
        uuid: id || '',
        params: '',
      })
        .then(result => {
          this.item = result.data.data.name
          setTimeout(() => {
            this.optionsProduct.unshift({ label: result.data.data.uuid, title: result.data.data.name })
            const option = this.getUniqueListBy(this.optionsProduct, 'label')
            this.optionsProduct = option
          }, 500)
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    onSearchItem(search) {
      this.searchQuery = search;
      if (search.length) {
        this.searchItem(search, this)
        this.page = 1
      } else {
        setTimeout(() => {
          this.optionsProduct = []
          this.page = 1
          this.getItems()
        }, 500)
      }
    },
    searchItem: _.debounce((search, vm) => {
      const queryParams = {
        search: search,
        warehouse_uuid: vm.formPayload.warehouse_origin_uuid || '',
        supplier_uuid: vm.formPayload.supplier_origin_uuid || '',
        has_stock: 1,
      };

      if (vm.formPayload.warehouse_origin_uuid) {
        queryParams.delivery_number_uuid = '';
      } else {
        queryParams.delivery_number_uuid = vm.formPayload.delivery_number;
      }

      queryParams.is_show_stock = true

      vm.$store.dispatch('parentProduct/getData', {
        uuid: '',
        params: queryParams,
      })
        .then(response => {
          // eslint-disable-next-line no-param-reassign
          vm.optionsProduct = response.data.data.data;
        })
    }, 300),
    async getItems() {
      try {
        const queryParams = {
          per_page: 10,
          warehouse_uuid: this.formPayload.warehouse_origin_uuid || '',
          supplier_uuid: this.formPayload.supplier_origin_uuid || '',
          has_stock: 1,
        };

        if (this.formPayload.warehouse_origin_uuid) {
          queryParams.delivery_number_uuid = '';
        } else {
          queryParams.delivery_number_uuid = this.formPayload.delivery_number;
        }

        queryParams.is_show_stock = true

        const result = await this.$store.dispatch('parentProduct/getData', {
          uuid: '',
          params: queryParams,
        });

        this.next_page_url = result.data.data.next_page_url;

        if (this.isSupplier && this.delivery_numbers.length) {
          this.formPayload.items = result.data.data.data.map(item => {
            return {
              product_uuid: item.uuid,
              qty: item.stock,
            };
          });
        }

        setTimeout(() => {
          this.optionsProduct = result.data.data.data;
        }, 300);
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
    },
    async loadMoreProduct() {
      if (this.next_page_url) {
        this.isLoadingLoadMore = true;
        const queryParams = {
          per_page: 10,
          warehouse_uuid: this.formPayload.warehouse_origin_uuid || '',
          supplier_uuid: this.formPayload.supplier_origin_uuid || '',
          has_stock: 1,
        };

        if (this.formPayload.warehouse_origin_uuid) {
          queryParams.delivery_number_uuid = '';
        } else {
          queryParams.delivery_number_uuid = this.formPayload.delivery_number;
        }

        queryParams.search = this.searchQuery;
        queryParams.page = ++this.page;
        queryParams.is_show_stock = true

        await this.$store.dispatch('parentProduct/getData', {
          uuid: '',
          params: queryParams,
        })
          .then(result => {
            this.next_page_url = result.data.data.next_page_url;
            this.optionsProduct = [...this.optionsProduct, ...result.data.data.data];
            this.isLoadingLoadMore = false;
          })
          .catch(err => {
            this.isLoadingLoadMore = false;
            console.log(err);
          });
      }
    },
    repeateAgain() {
      this.formPayload.items.push({
        product_uuid: '',
        qty: 0,
      })
    },
    removeItem(index) {
      this.formPayload.items.splice(index, 1)
    },
    disabledStep1(value) {
      if (value && this.step === 1) {
        this.disabled = true;
        const form = {
          date: value.date,
          warehouse_origin_uuid: value.warehouse_origin_uuid,
          warehouse_destination_uuid: value.warehouse_destination_uuid,
        };
        this.disabled = Object.values(form).some(e => e === '');
      }
    },
    checkIsQtyNumber(value) {
      if (typeof value == 'string') {
        const number = parseFloat(this.preformatFloat(value))
        return number > 0
      }
      if (typeof value == 'number') {
        return value > 0
      }
    },
    // nextSubmit() {
    //   if (this.step === 1) {
    //     this.$store.commit('formWizard/setStep', 2)
    //   }
    //   if (this.step === 2) {
    //     this.createItem()
    //   }
    // },
    nextSubmit() {
      this.createItem()
    },
    prevPage() {
      switch (this.step) {
        case 1:
          this.$router.go(-1)
          break
        case 2:
          this.$store.commit('formWizard/setStep', 1)
          break
        default:
          break
      }
    },
    stepForm() {
      switch (this.step) {
        case 1:
          this.progressValue = 50
          break
        case 2:
          this.progressValue = 100
          break
        default:
          break
      }
    },
    clearItem() {
      this.formPayload = {
        warehouse_origin_uuid: '',
        warehouse_destination_uuid: '',
        memo: '',
        items: [
          {
            product_uuid: '',
            qty: 0,
          },
        ],
      }
      this.optionsProduct = []
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map(item => [item[key], item])).values()]
    },
    getWarehouses() {
      this.$store.dispatch('warehouse/getData', {
        params: {
          branch_uuid: this.userData.branch.uuid
        },
      }).then(result => {
        this.optionsOrigin.push(...result.data.data)
        this.optionsDestination.push(...result.data.data)
        this.optionsOriginForBranch.push(...result.data.data)
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    getWarehousesDynamic() {
      this.$store.dispatch('warehouse/getData', {
        params: {
          branch_uuid: this.selectedBranch
        },
      }).then(result => {
        this.optionsDestinationDynamic = result.data.data;
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    getSupplier() {
      this.$store.dispatch('supplier/getData', {
        params: {
          // has_stock: 1,
          with_delivery_numbers: 1,
        },
      }).then(result => {
        this.optionsSupplier.push(...result.data.data)
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    getBranches() {
      this.$store.dispatch('cabang/getData', {
        uuid: '',
        params: '',
      }).then(result => {
        this.optionsBranch.push(...result.data.data.data)
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    createItem() {
      const form = this.preparePayload();

      this.isLoading = true;

      this.$swal({
        title: 'Konfirmasi',
        text: 'Apakah Anda yakin ingin melakukan transfer stok?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('stockWarehouse/postTransfer', {
            uuid: '',
            payload: form,
          })
            .then(() => {
              this.stock = 1;
              // this.$store.commit('formWizard/setStep', 1);
              successNotification(this, 'Success', 'Produk berhasil di transfer');
              this.clearItem();
              this.isLoading = false;
              this.$router.push({ name: 'stock.transfer' });
            })
            .catch((error) => {
              if (error.response.data.meta.messages) {
                this.messages = error.response.data.meta.messages;
              }
              this.$bvModal.hide('modal-save');
              this.isLoading = false;
            });
        } else {
          // Handle cancellation or other actions if needed
          this.isLoading = false;
        }
      });
    },
    updateItem() {
      this.formPayload.product_type = 'parent'
      const form = this.preparePayload()
      this.isLoading = true
      form.append('_method', 'PATCH')

      this.$store.dispatch('parentProduct/postData', {
        query: `/${this.editId}`,
        payload: form,
      })
        .then(() => {
          this.$store.commit('formWizard/setStep', 1)
          successNotification(this, 'Success', 'Produk berhasil di ubah!')
          this.clearItem()
          this.$bvModal.hide('modal-save')
          this.isLoading = false
          this.$router.push({ name: 'master-product.index' })
          this.$store.commit('formWizard/setStep', 1)
        })
        .catch(error => {
          if (error.response.data.meta.messages) {
            this.messages = error.response.data.meta.messages
          }
          this.$bvModal.hide('modal-save')
          this.isLoading = false
        })
    },
    preparePayload() {
      const form = new FormData();

      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          if (key !== 'items') {
            form.append(key, this.formPayload[key]);
          }
          if (this.delivery_numbers.length && key === 'delivery_number') {
            form.append('delivery_number_uuid', this.formPayload[key]);
          }
        }
      }

      for (let index = 0; index < this.formPayload.items.length; index++) {
        const element = this.formPayload.items[index];

        for (const key in element) {
          if (element.hasOwnProperty(key)) {
            const items = element[key];
            if (key !== 'product_uuid') {
              if (key !== 'qty' && key !== 'delivery_number') {
                form.append(`items[${index}][${key}]`, items);
              }
              if (key === 'qty' && !this.isSupplier) {
                form.append(`items[${index}][${key}]`, parseFloat(this.preformatFloat(items)));
              }
              if (key === 'qty' && this.isSupplier) {
                form.append(`items[${index}][${key}]`, items);
              }
            } else {
              if (this.isSupplier && this.delivery_numbers.length) {
                form.append(`items[${index}][${key}]`, items)
              } else {
                form.append(`items[${index}][${key}]`, items.label)
              }
            }
          }
        }
      }
      return form;
    },
    directPage() {
      this.clearItem()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

// body {
//   background-color: #fff !important;
// }

.bg-dark {
  background: $dark !important;
}

.btn__add--product {
  background-color: #FFFFFF !important;
  border: 1px solid #E4E5EC !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04) !important;
  border-radius: 8px;
  font-weight: 800;
}

.custom__input {
  width: 100%;

  &.error-validation {
    background: #FEEFEF;
    border: 1px solid #F48989;

    .input-group-text {
      background-color: #FEEFEF !important;
      border: 1px solid #F48989 !important;
    }
  }
}

.error-validation {
  .input-group-text {
    background-color: #FEEFEF !important;
    border: 1px solid #FEEFEF !important;
  }
}

.v-select {
  &.error-validation {
    .vs__dropdown-toggle {
      background: #FEEFEF !important;
      border: 1px solid #F48989 !important;
    }
  }
}

.vs__selected-options {
  overflow: hidden;
}

.progress-bar-success {
  background-color: #EFF1F5;

  .progress-bar {
    background: linear-gradient(269.89deg, #6CDFD4 0%, #45B6AB 98.81%) !important;
    box-shadow: 0px 6px 8px rgba(44, 129, 120, 0.06);
    border-radius: 20px;
  }
}

label {
  font-size: 14px;
  color: $dark;
  font-weight: 600;
}

.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}

.custom__input {
  height: 52px;
  background: #EFF1F5;
  border: 1px solid #E4E5EC;
  border-radius: 8px;
}

.vs__dropdown-toggle {
  height: 52px;
  background-color: #EFF1F5;
  border: 1px solid #E4E5EC;
  border-radius: 8px;
}
</style>
