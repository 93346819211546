<template>
    <div>
      <b-modal
        id="modal-add-product"
        hide-header
        static
        hide-footer
        title="Vertically Centered"
        ok-only
        ok-title="Accept"
        centered
      >
        <div class="p-2 d-flex justify-content-between">
          <feather-icon
            class="text-dark font-weight-bolder"
            size="20"
            icon="ArrowLeftIcon"
            style="cursor: pointer;"
            @click="$bvModal.hide('modal-add-product')"
          />
          <h1 class="h4 text-dark font-weight-bolder mb-0">
            Tambah Produk
          </h1>
          <div />
        </div>
        <hr class="my-0">
        <div class="pt-2">
          <div class="search__box--stock px-2">
            <!-- search -->
            <b-form-input
              v-model="filter.search"
              placeholder="Cari nama produk disini"
              class="focus-0 custom__input"
              style="height: 53px;"
            />
          </div>
          <!-- List product -->
          <div class="pb-2" style="max-height: 35rem;overflow-y: auto;">
            <b-card
              v-for="(product, index) in products"
              :key="index"
              no-body
              class="mb-0 mx-2 my-1 border-12"
              role="button"
            >
              <b-card-body class="p-2">
                <div class="d-flex">
                  <div class="image__container">
                    <b-img
                      :src="product.image_url"
                      width="47"
                      height="47"
                      alt="image"
                    />
                  </div>
                  <div class="product__information w-100 d-flex flex-column justify-content-between">
                    <div class="d-flex flex-row justify-content-between">
                      <div class="d-flex flex-column justify-content-between">
                        <h1 class="h5 text-dark fw-bold-800 mb-0 size14">
                          {{ product.name }}
                        </h1>
                        <!-- <h1 class="h5 text-dark text-darken-7 fw-bold-800 mb-0 size14">
                          {{ product.standard_price | formatAmount }}
                        </h1> -->
                        <small
                          class="fw-bold-500 text-dark text-darken-4"
                        >{{ product.stock }} {{ product.uom && product.uom.name }} {{ selectedWarehouseName ? ' di ' + selectedWarehouseName : '' }}</small>
                      </div>
                      <div class="d-flex justify-content-between mt-1">
                        <b-button class="px-2" size="sm" @click="chooseProducts(product)">
                          Pilih Produk
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card-body>
            </b-card>
            <div class="d-flex justify-content-center align-items-center" v-if="products.length > 0 && next_page_url != null">
              <b-button @click="loadMoreProduct">
                Load More
              </b-button>
            </div>
            <div v-if="products && products.length == 0">
              <div class="d-flex justify-content-center align-items-center">
                <div class="text-center">
                  <h6 class="h5 text-dark fw-bold-800 size14 mt-2">
                    <!-- Pilih barang untuk dimasukan keranjang -->
                    Oops!
                  </h6>
                  <h6 class="text-dark fw-bold-800 size14">
                    Hasil tidak ditemukan.
                  </h6>
                  <!-- <b-button @click="$bvModal.show('modal-add-product-is-null')" v-if="checkPermission('add produk')">
                    Tambah Produk
                  </b-button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </template>
  
  <script>
  // eslint-disable-next-line import/no-extraneous-dependencies
  import _ from 'lodash'
  import {
    BModal, VBToggle, BCard, BCardBody, BImg, BInputGroup, BFormInput, BInputGroupPrepend, BButton,
  } from 'bootstrap-vue'
  import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
  import Ripple from 'vue-ripple-directive'
  
  export default {
    components: {
      BModal,
      BCard,
      BCardBody,
      BImg,
      BInputGroup,
      BFormInput,
      BInputGroupPrepend,
      BButton,
    },
    directives: {
      'b-toggle': VBToggle,
      Ripple,
    },
    setup() {
      return {
        checkPermission, successNotification, errorNotification,
      }
    },
    data() {
      return {
        isLoadingLoadMore: false,
        is_ritel: true,
        discount: '0',
        products: [],
        filter: {
          search: '',
          per_page: 10,
        },
        page: 1,
        formPayload: {
          delivery_numbers_uuid: [],
          po_so_uuid: '',
          product_uuid: '',
          warehouse_uuid: '',
          qty: 0,
          price: 0,
          discounts: [],
          is_bonus: 0,
        },
        messages: '',
        id: '',
        typeWarehouse: '',
      }
    },
    props: {
      optionsProduct: {
        type: Array,
      },
      loadMoreProduct: {
        type: Function,
      },
      getItems: {
        type: Function,
      },
      selectedWarehouseName: {
        type: String,
      },
      next_page_url: {
        type: String
      }
    },
    watch: {
      'filter.search': _.debounce(function () {
        delete this.filter.page
        this.$emit('search', this.filter.search)
      }, 100),
      optionsProduct(newProduct){
        this.products = newProduct;
      },
    },
    methods: {
      chooseProducts(product) {
        this.$emit('selectedProduct', product)
        this.$bvModal.hide('modal-add-product')
      },
    },
  }
  </script>
  
  <style lang="scss">
  @import '/src/assets/scss/variables/_variables.scss';
  
  #modal-add-product .grid-container .grid-item.option-number:last-child {
    border-radius: 0px 16px 0px 0px;
  }
  #modal-add-product {
  
    .search__box--stock {
      display: flex;
      align-content: center;
      width: 100%;
    }
  
    .discount-field {
      height: 180px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      text-align: center;
  
      .discount-number {
        font-weight: 700;
        font-size: 42px;
        line-height: 150%;
        color: #333348;
      }
    }
  
    .grid-container {
      display: grid;
      grid-template-columns: auto auto auto;
  
      .grid-item {
        text-align: center;
        border-radius: 0;
        border: 0;
        width: 151px;
  
        &.option-number {
          background-color: #EFF1F5 !important;
          color: #000 !important;
          font-weight: 700;
          font-size: 14px;
  
          &:nth-child(3) {
            border-radius: 0px 16px 0px 0px;
          }
  
          &:first-child {
            border-radius: 16px 0px 0px 0px;
          }
        }
  
        &.list-number {
          color: #7A7F94 !important;
          background-color: #fff !important;
          padding: 20px;
          font-weight: 700;
          font-size: 28px;
  
          &:not(:last-child) {
            border-right: 1px solid #E4E5EC !important;
            border-bottom: 1px solid #E4E5EC !important;
          }
        }
  
        &.button-simpan {
          color: #fff !important;
          background-color: #CCCFD3 !important;
          font-size: 16px !important;
        }
  
        &:hover {
          box-shadow: none;
        }
      }
    }
  
    .modal-dialog {
      // position: absolute;
      // margin-bottom: 0;
      margin-left: auto !important;
      margin-right: auto !important;
      // left: 0;
      // right: 0;
      // bottom: 0;
      width: 37rem;
  
      .modal-content {
        background: #FCFCFC;
        // border-radius: 16px 16px 0px 0px;
  
        .modal-body {
          padding: 0;
  
          @media screen and (max-width: 800px) and (orientation: landscape) {
            overflow-y: scroll;
            height: 97vh;
          }
  
          .form-group {
            label {
              font-size: 14px;
              color: $dark;
              font-weight: 600;
            }
            input {
              background: #E7E7ED;
              border: none;
              border-radius: 8px;
              height: 52px;
              color: $dark;
              font-weight: 600;
            }
          }
          .button__group {
            display: flex;
            width: 100%;
  
            .button__cancel {
              background-color: #FFFFFF !important;
              border-color: #fff !important;
              color: $dark !important;
              box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
              border-radius: 0px 0px 0px 16px;
              width: 100%;
              height: 72px;
              font-size: 16px;
              font-weight: 800;
            }
  
            .button__save {
              background-color: #45B6AB !important;
              border-color: #45B6AB !important;
              box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
              border-radius: 0px 0px 16px 0px;
              width: 100%;
              height: 72px;
              font-size: 16px;
              font-weight: 800;
            }
  
          }
          .image__container {
            margin-right: 1rem;
  
            img {
              width: 47px;
              height: 47px;
              object-fit: cover;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }
  </style>
  